import { effect, signal } from "@lit-labs/preact-signals";
import { getDateUtc } from "./dayjs";

function createEventsStore() {
  // data
  const futureEvents = signal([]);
  const calendarData = signal([]);
  const eventsCategories = signal([]);
  const sessionsTopics = signal([]);

  // checks
  const calendarDataIsLoaded = signal(false);
  const hiddenMobileTimestamp = signal(null); // check for mobile to update the data, when the pwa was collapsed

  // sse
  const sseMessage = signal(null);
  const sseReceived = effect(() => _handleSSE());
  //sseReceived(); => to stop "effect"

  function _handleSSE() {
    console.log("SSE received in eventsStore: ", sseMessage.value);

    // update values here
    let slug = sseMessage.value?.slug;
    let data = sseMessage.value?.data;

    if (slug === "mentor-sessions") {
      updateDataWithSessions(data);
    } else if (slug === "events") {
      updateDataWithEvents(data);
    }
  }

  function updateDataWithEvents(data) {
    setTimeout(() => {
      if (data?.status === "pending") return;

      // update calendar data
      // if new event in the Calendar Data -> check the id's, if no => add in the Calendar
      if (!calendarData.value?.find((item) => item.data.id === data.id)) {
        if (!data?.suggested && !data?.organisation) return;

        console.log("eventsStore: added new event in calendar");
        let newEvent = {
          slug: "events",
          data: data,
          start: data.start,
          end: data.end,
        };

        let newCalendarData = [...calendarData.value, newEvent];
        newCalendarData.sort((a, b) => {
          let dateA = new Date(a.start);
          let dateB = new Date(b.start);
          return dateA - dateB;
        });
        calendarData.value = newCalendarData;
        console.log("eventsStore: new calendarData.value:", calendarData.value);
      } else {
        // update user's calendar
        let newCalendarData = [...calendarData.value].map((item) => {
          if (item.slug !== "events") return item;

          if (item.data.id === data.id) {
            return {
              slug: "events",
              data: data,
              start: data.start,
              end: data.end,
            };
          } else return item;
        });

        newCalendarData.sort((a, b) => {
          let dateA = new Date(a.start);
          let dateB = new Date(b.start);
          return dateA - dateB;
        });

        calendarData.value = newCalendarData;
        console.log("eventsStore: new calendarData.value:", calendarData.value);
      }

      // update future events data
      // check the start date -> end: { greater_than_equal: new Date () },
      if (getDateUtc(data.end) < getDateUtc(new Date())) {
        console.log(
          "eventsStore: past event, do not add to future events",
          data.end
        );
        return;
      }

      // if new event -> check the id's, if no => add in the this.futureEvents
      if (!futureEvents.value.find((item) => item.id === data.id)) {
        if (!data?.suggested && !data?.organisation) return;

        console.log("eventsStore: added new event in futureEvents");

        let newEventsData = [...futureEvents.value, data];
        newEventsData.sort((a, b) => {
          let dateA = new Date(a.start);
          let dateB = new Date(b.start);
          return dateA - dateB;
        });

        futureEvents.value = newEventsData;
        console.log("eventsStore: new futureEvents.value:", futureEvents.value);
      } else {
        let newEventsData = [...futureEvents.value].map((item) => {
          if (item.id === data.id) {
            return data;
          } else return item;
        });

        newEventsData.sort((a, b) => {
          let dateA = new Date(a.start);
          let dateB = new Date(b.start);
          return dateA - dateB;
        });

        futureEvents.value = newEventsData;
        console.log("eventsStore: new futureEvents.value:", futureEvents.value);
      }
    }, 0);
  }

  function updateDataWithSessions(data) {
    setTimeout(() => {
      if (
        data?.status === "accepted" ||
        data?.status === "rejected" ||
        data?.status === "request-pending" ||
        data?.status === "mentor-extra-hours-request-pending"
      )
        return;

      // remove the cancelled one
      if (
        data?.status === "cancelled-by-mentor" ||
        data?.status === "cancelled-on-time" ||
        data?.status === "cancelled-not-on-time"
      ) {
        let newCalendarData = [...calendarData.value].filter(
          (item) => item?.data?.id !== data?.id
        );
        console.log(
          "eventsStore: removed the cancelled session from calendarData"
        );
        calendarData.value = newCalendarData;
        console.log("eventsStore: new calendarData.value:", calendarData.value);
      } else {
        // update the session: can be added new one or rescheduled session
        if (!calendarData.value.find((item) => item.data.id === data.id)) {
          console.log("eventsStore: added new session in the calendar");

          let newSession = {
            slug: "mentor-sessions",
            data: data,
            start: data.start,
            end: data.end,
          };

          let newCalendarData = [...calendarData.value, newSession].sort(
            (a, b) => {
              let dateA = new Date(a.start);
              let dateB = new Date(b.start);
              return dateA - dateB;
            }
          );

          calendarData.value = newCalendarData;
          console.log(
            "eventsStore: new calendarData.value:",
            calendarData.value
          );
        } else {
          console.log("eventsStore: sessions is updated in calendarData");
          // update user's calendar
          let newCalendarData = [...calendarData.value]
            .map((item) => {
              if (item.data.id === data.id) {
                return {
                  slug: "mentor-sessions",
                  data: data,
                  start: data.start,
                  end: data.end,
                };
              } else return item;
            })
            .sort((a, b) => {
              let dateA = new Date(a.start);
              let dateB = new Date(b.start);
              return dateA - dateB;
            });

          calendarData.value = newCalendarData;
          console.log(
            "eventsStore: new calendarData.value:",
            calendarData.value
          );
        }
      }
    }, 0);
  }

  return {
    futureEvents,
    calendarData,
    eventsCategories,
    sessionsTopics,
    calendarDataIsLoaded,
    sseMessage,
    sseReceived,
    updateDataWithEvents,
    updateDataWithSessions,
    hiddenMobileTimestamp
  };
}
export const eventsStore = createEventsStore();
